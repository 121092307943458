
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getLedgerChatList, getLedgerChatObject, exportLedgerChatList, getSperviseUserChatHistory } from "@/api/request/pipelining";

//组件
@Component({
  name: "PrivateLetter",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private total: number = 0; //总数量
  private list: any[] = []; //私信列表
  private tableKey: number = 0; //列表
  private listLoading = true; //显示加载
  private sixinList: any[] = []; //私信类型

  //消息数据
  private activeChat: any = {}; //激活聊天
  private messageList: any[] = []; //消息列表
  private messageTitle: string = ""; //消息标题
  private messageTotal: number = 0; //消息总条数
  private messageDialog: boolean = false; //显示消息
  private messageLoading: boolean = false; //显示加载

  //列表参数
  private listQuery: any = {
    //页面数据
    page: 1,
    row: 20,

    //筛选数据
    excel: false,
    nickname: "",
    object_name: [],
    to_nickname: "",
    identity_type: 0,
    userid: undefined,
    to_identity_type: 0,
    to_userid: undefined,

    //时间数据
    time: [],
    end_time: "",
    start_time: "",
  };

  //消息参数
  private messageParams: any = {
    //页面数据
    page: 1,
    row: 10,

    //时间数据
    time: [],
    end_time: "",
    start_time: "",

    //用户数据
    user_rongyun_id: undefined,
    blogger_rongyun_id: undefined,
  };

  //身份类型
  private identityType: any[] = [
    { value: 0, label: "全部" },
    { value: 1, label: "常规用户" },
    { value: 2, label: "内部用户" },
    { value: 3, label: "自由博主" },
    { value: 4, label: "签约博主" },
  ];

  //时间参数
  private get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取私信类型
    this.getSiXinType();

    //获取列表
    this.getList();
  }

  //初始化时间
  private initTime(): void {
    //获取当天时间
    const currentDay = DTCls.getCurDateTimeYMD();

    //数据赋值
    this.listQuery.end_time = `${currentDay}`;
    this.listQuery.start_time = `${currentDay}`;
    this.listQuery.time = [this.listQuery.start_time, this.listQuery.end_time];
  }

  ////获取数据
  private async getList() {
    //显示加载
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);
    if (this.listQuery.to_userid) this.listQuery.to_userid = Number(this.listQuery.to_userid);

    //获取数据
    const { data } = await getLedgerChatList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //滚动到最上
  private scrollTop(): void {
    //获取组件
    const messageC: any = document.querySelector(".chat-messages");

    //滚动
    messageC.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  //添加消息
  private addMessage(): void {
    //数据赋值
    this.messageParams.row += 10;

    //获取消息
    this.getMessage(this.activeChat.to_rongyunid, this.activeChat.rongyunid);
  }

  //重置查询
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //页面数据
    this.listQuery.page = 1;

    //筛选数据
    this.listQuery.excel = false;
    this.listQuery.nickname = "";
    this.listQuery.to_nickname = "";
    this.listQuery.object_name = [];
    this.listQuery.identity_type = 0;
    this.listQuery.userid = undefined;
    this.listQuery.to_identity_type = 0;
    this.listQuery.to_userid = undefined;

    //获取数据
    this.getList();
  }

  //处理Exel
  private async handleExcel() {
    //数据赋值
    this.listQuery.excel = true;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);
    if (this.listQuery.to_userid) this.listQuery.to_userid = Number(this.listQuery.to_userid);

    //聊天列表处理
    const { data } = await exportLedgerChatList(this.listQuery);

    //保存文档
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}私信列表`) : saveAs(data, "私信列表总览");
  }

  //获取私信类型
  private async getSiXinType() {
    //显示加载
    this.listLoading = true;

    //获取数据据
    const res = await getLedgerChatObject();

    //数据赋值
    this.sixinList = res.data;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //隐藏聊天详情
  private messageCancel(): void {
    //隐藏界面
    this.messageDialog = false;

    //滚动到最上面
    this.scrollTop();
  }

  //接受人身份
  private identityFormat(row: any): any {
    //数据赋值
    let identity = row.identity_type;

    //发送人身份(1:常规用户, 2:内部用户, 3:自由博主, 4:签约博主)
    if (identity === 1) return "常规用户";
    if (identity === 2) return "内部用户";
    if (identity === 3) return "自由博主";
    if (identity === 4) return "签约博主";
  }

  //发送人身份
  private toIdentityFormat(row: any): any {
    //数据赋值
    let identity = row.to_identity_type;

    //发送人身份(1:常规用户, 2:内部用户, 3:自由博主, 4:签约博主)
    if (identity === 1) return "常规用户";
    if (identity === 2) return "内部用户";
    if (identity === 3) return "自由博主";
    if (identity === 4) return "签约博主";
  }

  //聊天详情
  private handleChatDetail(row: any): void {
    //数据赋值
    this.activeChat = row;
    this.messageParams.page = 1;
    this.messageParams.row = 10;
    this.messageTitle = `${row.nickname}  与  ${row.to_nickname}的聊天记录`;

    //数据赋值
    this.getMessage(row.to_rongyunid, row.rongyunid);

    //显示界面
    this.messageDialog = true;
  }

  //获取消息
  private async getMessage(blogger_id: string, user_id: string) {
    //显示加载
    this.messageLoading = true;

    //数据赋值
    this.messageParams.user_rongyun_id = user_id;
    this.messageParams.blogger_rongyun_id = blogger_id;
    if (this.messageParams.time && this.messageParams.time.length == 2) {
      this.messageParams.start_time = `${this.messageParams.time[0]} 00:00:00`;
      this.messageParams.end_time = `${this.messageParams.time[1]} 23:59:59`;
    } else {
      this.messageParams.start_time = "";
      this.messageParams.end_time = "";
    }

    //获取数据
    const { data } = await getSperviseUserChatHistory(this.messageParams);

    //列表赋值
    this.messageList = data.list;
    this.messageTotal = data.total;

    //隐藏加载
    this.messageLoading = false;
  }
}
